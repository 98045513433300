import React from "react"
import truncate from 'truncate-html';
export default class TruncatedHtml extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  render() {
    const {body} = this.props
    const {expanded} = this.state
    const truncatedText = truncate(body, 700)
    const hasMore = 700 < body.length

    return (
      <>
      <div className="component__truncated-html" dangerouslySetInnerHTML={{__html: !expanded ? truncatedText : body }}></div>
      {hasMore && !expanded && (
        <button className="uk-text-uppercase uk-button uk-button-reset uk-padding-remove-left uk-text-primary button-transparent" onClick={() => this.setState({expanded: !expanded})}>Read more</button>
      )}
      </>
    )
  }
}
